<!--@Time : 2021/11/16 10:46-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<p class="text-center text-h6">
					Interactive Experience Contest Scores
				</p>
			</v-col>
			<v-col cols="12" md="12" lg="12">
				<v-container>
					<div id="container" style="position: relative">
						<Loading
							v-if="!data.length"
							:loading="loading"
						></Loading>
					</div>
				</v-container>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<p class="text-h6 mt-8 mb-4 font-weight-bold text-uppercase">
					Your Performance
				</p>
				<v-alert
					prominent
					type="success"
					class="text-h6 font-weight-medium"
					v-text="'Score Earned: ' + Number(score).toFixed(3)"
				></v-alert>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Media (25%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="mediaHeaders"
							:items="mediaDesserts"
							:loading="mediaLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('media')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="mediaScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Theme Day (25%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="themeHeaders"
							:items="themeDesserts"
							:loading="themeLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('theme')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="themeScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Into SDC House (25%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="intoSDCHouseHeaders"
							:items="intoSDCHouseDesserts"
							:loading="intoSDCHouseLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('into_sdc_house')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="intoSDCHouseScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="$store.getters.team">
			<v-col cols="12" md="12" lg="12">
				<v-card outlined>
					<v-card-title>Into SDC Community (25%)</v-card-title>
					<v-card-text>
						<v-data-table
							:headers="intoSDCCommunityHeaders"
							:items="intoSDCCommunityDesserts"
							:loading="intoSDCCommunityLoading"
							class="elevation-0"
							hide-default-footer
						>
							<template
								v-if="isShow"
								v-slot:item.action="{ item }"
							>
								<v-icon
									color="primary"
									small
									class="mr-2"
									@click="editItem(item)"
									>mdi-pencil</v-icon
								>
								<v-icon
									color="error"
									small
									@click="deleteItem(item)"
									>mdi-delete</v-icon
								>
							</template>
						</v-data-table>
						<v-btn
							class="mt-5"
							v-if="isShow"
							color="primary"
							outlined
							small
							@click="addItem('into_sdc_community')"
						>
							Insert an Item
						</v-btn>
						<SubContestLabel
							class="mt-5"
							:score="intoSDCCommunityScore"
							v-if="$store.getters.team"
						></SubContestLabel>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-model="dialog" max-width="600px" @click:outside="clearData">
			<v-card>
				<v-card-title class="mb-3">New Item</v-card-title>
				<v-card-text>
					<v-row>
            <v-col cols="6">
              <v-datetime-picker
                  v-model="editedItem.starting_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  label="Starting Time"
              ></v-datetime-picker>
            </v-col>
            <v-col cols="6">
              <v-datetime-picker
                  v-model="editedItem.ending_time"
                  :time-picker-props="timeProps"
                  time-format="HH:mm:ss"
                  label="Ending Time"
              ></v-datetime-picker>
            </v-col>
						<template v-if="task === 'media'">
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.media_votes"
									label="Media Votes"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'theme'">
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.theme"
									label="Theme"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.score_earned"
									label="Score Earned"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'into_sdc_house'">
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.broadcast_platforms"
									label="Broadcast Platforms"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.number_of_views"
									label="Number of Views"
									suffix="k"
								></v-text-field>
							</v-col>
						</template>
						<template v-if="task === 'into_sdc_community'">
							
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.observer_name"
									label="Observer Name"
								>
								</v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.decathlete_name"
									label="Decathlete Name"
								></v-text-field>
							</v-col>
							<v-col cols="6">
								<v-text-field
									v-model="editedItem.number_of_likes"
									label="Number of Likes"
									suffix="k"
								>
								</v-text-field>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clearData"
						>Cancel</v-btn
					>
					<v-btn
						v-if="save"
						color="primary"
						text
						small
						@click="saveData"
						>Save</v-btn
					>
					<v-btn v-else color="primary" text small @click="updateData"
						>Update</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="closeDelete"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteItemConfirm"
						>Yes
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "../../components/Header";
import { Chart } from "@antv/g2";
import { DataView } from "@antv/data-set";
import Loading from "@/components/Loading";
import {
	addContest,
	delContest,
	listContest,
	updateContestPatch,
} from "@/api/solar/contest";
import { Message } from "element-ui";
import SubContestLabel from "../../components/SubContestLabel";
import { listInteractiveScore } from "@/api/solar/sensor";
import moment from "moment";
export default {
	name: "Interactive",
	components: { SubContestLabel, Loading, Header },
	data() {
		return {
			score: 0,
			mediaScore: 0,
			themeScore: 0,
			intoSDCHouseScore: 0,
			intoSDCCommunityScore: 0,
			data: [],
			chart: null,
			loading: "loading...",

			isShow:
				this.$store.getters.groups === 1 ||
				this.$store.getters.groups === 2,

			mediaHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Media Votes", value: "media_votes" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			mediaDesserts: [],

			themeHeaders: [
				{ text: "#", value: "no" },
				{ text: "Theme", value: "theme" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			themeDesserts: [],

			intoSDCHouseHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Broadcast Platforms", value: "broadcast_platforms" },
				{ text: "Number of Views (k)", value: "number_of_views" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			intoSDCHouseDesserts: [],

			intoSDCCommunityHeaders: [
				{ text: "#", value: "no" },
				{ text: "Starting Time", value: "starting_time" },
				{ text: "Ending Time", value: "ending_time" },
				{ text: "Number of Likes (k)", value: "number_of_likes" },
				{ text: "Observer Name", value: "observer_name" },
				{ text: "Decathlete Name", value: "decathlete_name" },
				{ text: "Score Earned", value: "score_earned" },
				{ text: "Action", value: "action" },
			],
			intoSDCCommunityDesserts: [],

			mediaLoading: false,
			themeLoading: false,
			intoSDCHouseLoading: false,
			intoSDCCommunityLoading: false,

			dialog: false,
			dialogDelete: false,
			save: true,
			Date: {},
			task: "",

			editedItem: {
        starting_time: null,
        ending_time: null,
				theme: null,
				media_votes: null,
				broadcast_platforms: null,
				number_of_views: null,
				number_of_likes: null,
				observer_name: null,
				decathlete_name: null,
				score_earned: null,
			},

      timeProps: {
        useSeconds: true,
        ampmInTitle: true,
      },
		};
	},

	created() {
		this.getScore();
	},

	mounted() {
		this.setChart();
		this.getContest(null);
	},

  beforeDestroy() {
    this.chart.destroy()
  },

  watch: {
		"$store.getters.team": {
			handler() {
        this.getScore()
				this.getContest(null);
			},
		},
	},

	computed: {},

	methods: {
		async setChart() {
			this.chart = new Chart({
				container: "container",
				autoFit: true,
				height: 500,
			});
			this.chart.data(this.data);
			this.chart.scale("score", {
				min: 0,
				max: 100,
				tickInterval: 10,
			});
			this.chart.legend({
				position: "bottom",
				flipPage: false,
				items: [
					{ name: "Media", marker: { style: { fill: "#e74c3c" } } },
					{ name: "Theme Day", marker: { style: { fill: "#6395F9" } } },
					{
						name: "Into SDC House",
						marker: { style: { fill: "#61D9AB" } },
					},
					{
						name: "Into SDC Community",
						marker: { style: { fill: "#657798" } },
					},
				],
			});
			this.chart.coordinate().transpose();
			this.chart.tooltip({
				shared: true,
				showMarkers: false,
        customItems: (items) => {
          items.forEach((item)=>{
            item.value = Number(item.value).toFixed(3)
          })
          return items;
        },
			});
			this.chart.interaction("active-region");
			this.chart
				.interval()
				.adjust("stack")
				.position("team*score")
				.color("contest*team", (contest) => {
					if (contest === "Media") {
						return "#e74c3c";
					}
					if (contest === "Theme Day") {
						return "#6395F9";
					}
					if (contest === "Into SDC House") {
						return "#61D9AB";
					}
					if (contest === "Into SDC Community") {
						return "#657798";
					}
					if (contest === "Total") {
						return "rgba(255,255,255,0)";
					}
				})
				.label("score", (score) => {
					if (score === 0) {
						return null;
					}
					return {
            content: score.toFixed(3),
						fill: "#07021a",
						position: "left",
					};
				});
			this.chart.render();
		},

		getScore() {
			listInteractiveScore()
				.then((res) => {
					this.data = new DataView().source(res).transform({
						type: "fold",
						fields: [
							"Media",
							"Theme Day",
							"Into SDC House",
							"Into SDC Community",
							"Total",
						], // 展开字段集
						key: "contest", // key字段
						value: "score", // value字段
						retains: ["team"], // 保留字段集，默认为除 fields 以外的所有字段
					}).rows;
					if (this.$store.getters.team) {
						res.forEach((item) => {
							if (
								item.team === this.$store.getters.team.name_en
							) {
								this.mediaScore = item["Media"];
								this.themeScore = item["Theme Day"];
								this.intoSDCHouseScore = item["Into SDC House"];
								this.intoSDCCommunityScore = item["Into SDC Community"];
								this.score = item.Total;
							}
						});
					}
					this.chart.changeData(this.data);
				})
				.catch();
		},

		// 清除数据
		clearData() {
			this.dialog = false;
			this.Date = {};
			this.editedItem = {
        starting_time: null,
        ending_time: null,
				theme: null,
				media_votes: null,
				broadcast_platforms: null,
				number_of_views: null,
				number_of_likes: null,
				observer_name: null,
				decathlete_name: null,
				score_earned: null,
			}
		},

		// 打开编辑页面
		editItem(item) {
			this.save = false;
			this.task = item.task;
			this.Date = item;
      this.editedItem.starting_time = item.starting_time;
			this.editedItem.ending_time = item.ending_time;
			this.editedItem.theme = item.theme;
			this.editedItem.media_votes = item.media_votes;
			this.editedItem.broadcast_platforms = item.broadcast_platforms;
			this.editedItem.number_of_likes = item.number_of_likes;
			this.editedItem.number_of_views = item.number_of_views;
			this.editedItem.observer_name = item.observer_name;
			this.editedItem.decathlete_name = item.decathlete_name;
			this.editedItem.score_earned = item.score_earned;
			this.dialog = true;
		},

		// 打开删除页面
		deleteItem(item) {
			this.dialogDelete = true;
			this.Date = item;
		},

		// 关闭删除页面
		closeDelete() {
			this.dialogDelete = false;
			this.clearData();
		},

		// 打开添加页面
		addItem(task) {
			this.clearData();
			this.Date.task = task;
			this.task = task;
			this.save = true;
			this.dialog = true;
		},

		// 获取得分
		getContest(task) {
			if (this.$store.getters.team) {
				const params = {
					team: this.$store.getters.team.id,
					type: "interactive",
					pagesize: 100,
				};
				if (task !== null) {
					params["task"] = task;
					switch (task) {
						case "media":
							this.mediaLoading = true;
							break;
						case "theme":
							this.themeLoading = true;
							break;
						case "into_sdc_house":
							this.intoSDCHouseLoading = true;
							break;
						case "into_sdc_community":
							this.intoSDCCommunityLoading = true;
							break;
					}
					this.itemLoading = true;
					listContest(params).then((res) => {
						switch (task) {
							case "media":
								this.mediaDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.mediaDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										media_votes: data.media_votes,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
								});
								this.mediaLoading = false;
								break;
							case "theme":
								this.themeDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.themeDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										theme: data.theme,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
								});
								this.themeLoading = false;

								break;
							case "into_sdc_house":
								this.intoSDCHouseDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.intoSDCHouseDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										broadcast_platforms: data.broadcast_platforms,
										number_of_views: data.number_of_views,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
								});
								this.intoSDCHouseLoading = false;
								break;
							case "into_sdc_community":
								this.intoSDCCommunityDesserts = [];
								res["results"].forEach((item, index) => {
									const data = JSON.parse(item["data"]);
									this.intoSDCCommunityDesserts.push({
										no: index + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										number_of_likes: data.number_of_likes,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
								});
								this.intoSDCCommunityLoading = false;
								break;
						}
					});
				} else {
					this.mediaLoading = true;
					this.themeLoading = true;
					this.intoSDCHouseLoading = true;
					this.intoSDCCommunityLoading = true;
					listContest(params).then((res) => {
						this.mediaDesserts = [];
						this.themeDesserts = [];
						this.intoSDCHouseDesserts = [];
						this.intoSDCCommunityDesserts = [];
						res["results"].forEach((item) => {
							const data = JSON.parse(item["data"]);
							switch (item["task"]) {
								case "media":
									this.mediaDesserts.push({
										no: this.mediaDesserts.length + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										media_votes: data.media_votes,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "theme":
									this.themeDesserts.push({
										no: this.themeDesserts.length + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										theme: data.theme,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "into_sdc_house":
									this.intoSDCHouseDesserts.push({
										no:
											this.intoSDCHouseDesserts.length +
											1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										broadcast_platforms:
											data.broadcast_platforms,
										number_of_views: data.number_of_views,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
									break;
								case "into_sdc_community":
									this.intoSDCCommunityDesserts.push({
										no:
											this.intoSDCCommunityDesserts
												.length + 1,
										id: item.id,
										task: item.task,
                    starting_time: data.starting_time,
                    ending_time: data.ending_time,
										number_of_likes: data.number_of_likes,
										observer_name: data.observer_name,
										decathlete_name: data.decathlete_name,
										score_earned: item.score.toFixed(3),
									});
									break;
							}
						});
						this.mediaLoading = false;
						this.themeLoading = false;
						this.intoSDCHouseLoading = false;
						this.intoSDCCommunityLoading = false;
					});
				}
			}
		},

		// 保存
		saveData() {
      if (this.editedItem.starting_time == null || this.editedItem.ending_time == null) {
        Message({
					message: "未选择时间",
					type: "error",
				});
				return;
			} else {
        this.editedItem.starting_time = moment(this.editedItem.starting_time).format("YYYY-MM-DD HH:mm:ss")
        this.editedItem.ending_time = moment(this.editedItem.ending_time).format("YYYY-MM-DD HH:mm:ss")
      }
			addContest({
				team: this.$store.getters.team.id,
				type: "interactive",
				task: this.Date.task,
				score: this.editedItem.score_earned,
				data: JSON.stringify(this.editedItem),
			}).then(() => {
				Message({
					message: "Upload Successful",
					type: "success",
				});
				this.getScore();
				this.getContest(this.Date.task);
			});
			this.dialog = false;
		},

		// 更新
		updateData() {
      if (this.editedItem.starting_time == null || this.editedItem.ending_time == null) {
        Message({
					message: "未选择时间",
					type: "error",
				});
				return;
			} else {
        this.editedItem.starting_time = moment(this.editedItem.starting_time).format("YYYY-MM-DD HH:mm:ss")
        this.editedItem.ending_time = moment(this.editedItem.ending_time).format("YYYY-MM-DD HH:mm:ss")
      }
			updateContestPatch(this.Date.id, {
				score: this.editedItem.score_earned,
				data: JSON.stringify(this.editedItem),
			}).then(() => {
				Message({
					message: "Update Successful",
					type: "success",
				});
				this.getScore();
				this.getContest(this.Date.task);
			});
			this.dialog = false;
		},

		// 删除
		deleteItemConfirm() {
			delContest(this.Date.id).then(() => {
				this.getScore();
				this.getContest(this.Date.task);
				Message({
					message: "Delete Successful",
					type: "success",
				});
			});
			this.dialogDelete = false;
		},
	},
};
</script>

<style scoped>
</style>